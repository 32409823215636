import React from "react"
import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

const PostTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: { post_id, title, preview, category, slug, image },
      body,
    },
  } = data

  const articleUrl = `${post_id}-${slug}`
  const { publicURL: imageUrl } = image

  return (
    <Layout>
      <SEO
        title={title}
        description={preview}
        articleUrl={articleUrl}
        imageUrl={imageUrl}
      />
      <section className="article">
        <div className="article-header">
          <motion.div
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ type: "linear" }}
            className="wrapper"
          >
            <h2>{title}</h2>
          </motion.div>
        </div>
        <div className="article-content">
          <motion.div
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ type: "linear" }}
            className="wrapper"
          >
            <MDXRenderer>{body}</MDXRenderer>
            <div className="article-btn">
              <Link
                to="/"
                className={`btn ${
                  category === "barca" ? "btn-barca" : "btn-other"
                }`}
              >
                Powrót
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        post_id
        title
        category
        slug
        preview
        image {
          publicURL
        }
      }
      body
    }
  }
`
export default PostTemplate
